// |--------------------------------------------------------------------------
// | Add to calendar
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .pane-add-to-calendar-panel-pane-1
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin pane-add-to-calendar-panel-pane-1-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.pane-add-to-calendar-panel-pane-1 {

  .btn {
    @extend .btn-primary;
    @extend .btn-lg;

    max-width: 100%;
    white-space: normal;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
