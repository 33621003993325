// |--------------------------------------------------------------------------
// | Teaser
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .entity-teaser--os2web-kulturnaut-knactivity
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// Responsive
$xs-entity-teaser-kultunaut-event-heading-min-height: 50px;
$sm-entity-teaser-kultunaut-event-heading-min-height: 50px;
$md-entity-teaser-kultunaut-event-heading-min-height: 50px;
$lg-entity-teaser-kultunaut-event-heading-min-height: 50px;

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin entity-teaser-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.entity-teaser--os2web-kulturnaut-knactivity {
  position: relative;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .entity-teaser__date {
    position: absolute;

    top: $entity-teaser-body-padding-left-right;
    right: $entity-teaser-body-padding-left-right;

    background-color: rgba($entity-teaser-background-color, .7);

    border: 0;

    .field {
    }
  }

  .entity-teaser__heading {
    min-height: $xs-entity-teaser-kultunaut-event-heading-min-height;

    + .flexy-list {
      margin-top: 10px;
    }
  }

  .entity-teaser__image {
    min-height: 220px;

    flex-grow: 1;
  }

  .flexy-list {

    > * {
      display: inline-block;

      &:after {
        // [converter] Workaround for https://github.com/sass/libsass/issues/1115
        $nbsp: "\00a0";
        content: "|#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
        padding: 0 5px;
      }

      &:last-child {

        &:after {
          display: none;
        }
      }
    }
  }

  .entity-teaser__top-level-venue {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
  }
  .entity-teaser__current-venue {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
  }

  .entity-teaser__body {
    background-color: rgba($entity-teaser-background-color, .7);
  }



  &:hover {

    .entity-teaser__date {
      background-color: rgba(0, 0, 0, .4);

      border-color: #fff;

      .field {
        color: #fff;
      }
    }

    .entity-teaser__heading__title {
      color: #fff;
    }

    .entity-teaser__body {
      background-color: transparent;
      background-image: linear-gradient(
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, .27),
                      rgba(0, 0, 0, .34),
                      rgba(0, 0, 0, .45),
                      rgba(0, 0, 0, .5)
      );
    }

    .entity-teaser__top-level-venue {
      color: #fff;
    }
    .entity-teaser__current-venue {
      color: #fff;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  .entity-teaser--os2web-kulturnaut-knactivity {

    .entity-teaser__heading {
      min-height: $sm-entity-teaser-kultunaut-event-heading-min-height;
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  .entity-teaser--os2web-kulturnaut-knactivity {

    .entity-teaser__heading {
      min-height: $md-entity-teaser-kultunaut-event-heading-min-height;
    }
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  .entity-teaser--os2web-kulturnaut-knactivity {

    .entity-teaser__heading {
      min-height: $lg-entity-teaser-kultunaut-event-heading-min-height;
    }
  }
}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
