// |--------------------------------------------------------------------------
// | Sitemap
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .sectioned--sitemap
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin sectioned-sitemap-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.sectioned--sitemap {
  background-color: $body-bg-tertiary;

  &,
  a {
    color: #969899;
  }

  a:hover {
    color: #fff;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  .menu {

    .menu--hide {
      display: none;
    }

    > li {
      margin-bottom: floor( ( $grid-gutter-width / 2 ) );

      > a {
        font-size: $font-size-large;

        color: #fff;
      }
    }

    a {
      display: block;

      padding: 5px $grid-gutter-width 5px 0;

      &,
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  .social-icons {
    margin-top: 30px;
  }
}

.sectioned--sitemap__logo {

  img {
    height: 50px;
  }

  + .sectioned--sitemap__kultunaut-logo {
    margin-top: $grid-gutter-width;
  }

  + .sectioned--sitemap__description {
    margin-top: $grid-gutter-width;
  }
}

.social-icons {

  + .sectioned--sitemap__kultunaut-logo {
    margin-top: $grid-gutter-width;
  }
}

.sectioned--sitemap__kultunaut-logo {

  img {
    height: 26px;
  }

  + .sectioned--sitemap__description {
    margin-top: $grid-gutter-width;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  .sectioned--sitemap {

    .menu {
      display: block;

      > li {
        float: left;

        min-width: 140px;
      }
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .sectioned--sitemap {

    .menu {
      display: flex;

      flex-wrap: wrap;

      width: 100%;

      > li {
        flex-grow: 1;
      }
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
