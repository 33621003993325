// Colouring
$sectioned-default-background: $body-bg !default;
$sectioned-primary-background: $brand-primary !default;
$sectioned-secondary-background: $brand-secondary !default;
$sectioned-tertiary-background: $brand-tertiary !default;
$sectioned-quaternary-background: $brand-quaternary !default;

// Responsive
$xs-sectioned-inner-spacing-sm: 10px;
$xs-sectioned-inner-spacing: 15px;
$xs-sectioned-inner-spacing-lg: 30px;

$sm-sectioned-inner-spacing-sm: 10px;
$sm-sectioned-inner-spacing: 20px;
$sm-sectioned-inner-spacing-lg: 40px;

$md-sectioned-inner-spacing-sm: 10px;
$md-sectioned-inner-spacing: 20px;
$md-sectioned-inner-spacing-lg: 40px;

$lg-sectioned-inner-spacing-sm: 15px;
$lg-sectioned-inner-spacing: 20px;
$lg-sectioned-inner-spacing-lg: 40px;
