// |--------------------------------------------------------------------------
// | Facets
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .boxy--facets
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin boxy-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.boxy--facets {

  .boxy__heading {
    padding-left: 0;
    padding-right: 0;

    margin-bottom: $boxy-body-spacing-top-bottom;
  }

  .facetapi-facetapi-links {

    li {
      list-style: initial;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
