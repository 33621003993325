$img-path: "../images";
$font-path: "../fonts";

$fa-var-angle-down: "";

// Social
$social-link-color-facebook:    #3b5998;
$social-link-color-twitter:     #55acee;
$social-link-color-instagram:   #3f729b;
$social-link-color-linkedin:    #0976b4;
$social-link-color-pinterest:   #cc2127;
$social-link-color-vimeo: 	    #1ab7ea;
$social-link-color-youtube:     #e52d27;
$social-link-color-google-plus: #d64431;
