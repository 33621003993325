// |--------------------------------------------------------------------------
// | Teaser
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .entity-teaser--os2web-taxonomies-tax-places
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

$entity-teaser-place-body-padding-top-bottom: $grid-gutter-width;
$entity-teaser-place-body-padding-left-right: floor( ( $grid-gutter-width / 2 ) );

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin entity-teaser-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.entity-teaser--os2web-taxonomies-tax-places {

  .entity-teaser__heading {
    position: relative;

    padding-top: 20px;
  }
  .entity-teaser__heading__title {

    &:before {
      position: absolute;

      top: 0;
      left: 0;

      content: "";

      height: 2px;
      width: 80px;

      background-color: $brand-primary;
    }
  }

  .entity-teaser__image {

    img {
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
    }
  }

  .entity-teaser__body {
    flex-grow: 1;

    padding: $entity-teaser-place-body-padding-top-bottom $entity-teaser-place-body-padding-left-right;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.entity-teaser--os2web-taxonomies-tax-places {

  .entity-teaser__heading__title {

    &:before {
      background-color: $brand-primary;
    }
  }


  &.entity-top-level--for-boern {

    .entity-teaser__heading__title {

      &:before {
        background-color: $oplev-syddjurs-top-level-for-boern;
      }
    }
  }

  &.entity-top-level--kunst-og-kultur {

    .entity-teaser__heading__title {

      &:before {
        background-color: $oplev-syddjurs-top-level-kunst-og-kultur;
      }
    }
  }

  &.entity-top-level--natur-og-friluftsliv {

    .entity-teaser__heading__title {

      &:before {
        background-color: $oplev-syddjurs-top-level-natur-og-friluftsliv;
      }
    }
  }

  &.entity-top-level--sport-og-fritid {

    .entity-teaser__heading__title {

      &:before {
        background-color: $oplev-syddjurs-top-level-sport-og-fritid;
      }
    }
  }
}

.sectioned--dark {

  .entity-teaser--os2web-taxonomies-tax-places {

    .entity-teaser__body {
      background-color: #fff;
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
