// |--------------------------------------------------------------------------
// | Teaser
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .entity-teaser
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

$entity-teaser-background-color: $body-bg-secondary;
$entity-teaser-body-padding-top-bottom: floor( ( $grid-gutter-width / 2 ) );
$entity-teaser-body-padding-left-right: floor( ( $grid-gutter-width / 2 ) );

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin entity-teaser-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.entity-teaser {
  display: flex;

  flex-grow: 1;
  flex-direction: column;

  margin-bottom: $grid-gutter-width;

  border-radius: $border-radius-base;

  background-color: $entity-teaser-background-color;
}

.entity-teaser__inner {
  display: flex;

  flex-grow: 1;
  flex-direction: column;
}

.entity-teaser__date {
  width: 100px;

  padding: 10px;

  text-align: center;

  border-radius: $border-radius-base;
  border: 2px solid $text-color;

  .field {
    @extend .heading-h4;

    margin: 0;
    padding: 0;
  }
}

.entity-teaser__image {

  img {
    width: 100%;
  }
}

.entity-teaser__heading {}
.entity-teaser__heading__title {
  padding: 0;
  margin: 0;
}

.entity-teaser__body {
  padding: $entity-teaser-body-padding-top-bottom $entity-teaser-body-padding-left-right;
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
