// |--------------------------------------------------------------------------
// | Sectioned top light/dark/light/dark
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .panels-layout--sectioned-top-light-dark-light-dark
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin panels-layout-content-with-left-and-right-sidebar-top-footer-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.panels-layout--sectioned-top-light-dark-light-dark {

  .layout__content {
    padding-bottom: 0;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
