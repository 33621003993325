// |--------------------------------------------------------------------------
// | Sectioned
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .sectioned
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

.sectioned {}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.sectioned--dark {
  @include sectioned-mixin($body-bg-secondary);
}

.sectioned--light {
  @include sectioned-mixin($body-bg);
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
