// |--------------------------------------------------------------------------
// | Places
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .panels-layout--content-with-left-and-right-sidebar-top-footer
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin panels-layout-content-with-left-and-right-sidebar-top-footer-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.panels-layout--content-with-left-and-right-sidebar-top-footer {}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.page-taxonomy-term {

  &.panels-layout--content-with-left-and-right-sidebar-top-footer {

    .panel-separator {
      height: $panel-separator-height;
    }

    &.panels-display--standard {

      .panels-pane-region--top-container {
        margin-bottom: 0;
      }
    }

    &.panels-display--variant-2 {

      .panels-pane-region--content-first {
        margin-bottom: 0;
      }

      .panels-pane-region--content-secondary {
        @extend .boxy__body;
      }

      .panels-pane-region--sidebar-right {
        @extend .boxy__body;

        padding-top: $boxy-spacing-left-right;
        padding-bottom: $boxy-spacing-left-right;

        border: 0;

        box-shadow: $boxy-shadow-body-box-shadow;
        background-color: $boxy-shadow-body-background-color;
      }
    }

    &.panels-display--variant-3 {}

    &.panels-display--variant-4 {

      .panels-pane-region--top-container {
        margin-bottom: 40px;
      }
    }

    &.panels-display--variant-6 {

      .panels-pane-region--content-first {
        margin-bottom: 0;
      }

      .panels-pane-region--content-secondary {
        @extend .boxy__body;
      }

      .panels-pane-region--sidebar-right {
        margin-top: $xs-breadcrumb-padding-vertical;
      }
    }
  }

  &.panels-layout--sectioned-top-light-dark-light-dark {

    &.panels-display--variant-1 {

      .breadcrumb {
        margin-top: 0 - $xs-sectioned-inner-spacing;
      }

      .panels-pane-region--light-section-1 {
        margin-bottom: 25px;
      }

      .views-exposed-form {

        .views-widget-filter-date_filter {
          flex-grow: 0;
        }
      }
    }
  }
}


// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  .page-taxonomy-term {

    &.panels-layout--content-with-left-and-right-sidebar-top-footer {

      &.panels-display--variant-2 {

        .panels-pane-region--sidebar-right {
          margin-top: -50px;
        }
      }

      &.panels-display--variant-4 {

        .panels-pane-region--top-container {
          margin-bottom: 60px;
        }
      }

      &.panels-display--variant-6 {

        .panels-pane-region--sidebar-right {
          margin-top: $sm-breadcrumb-padding-vertical;
        }
      }
    }

    &.panels-layout--sectioned-top-light-dark-light-dark {

      &.panels-display--variant-1 {

        .breadcrumb {
          margin-top: 0 - $sm-sectioned-inner-spacing;
        }

        .panels-pane-region--light-section-1 {
          margin-bottom: 40px;
        }
      }
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .page-taxonomy-term {

    &.panels-layout--content-with-left-and-right-sidebar-top-footer {

      &.panels-display--variant-4 {

        .panels-pane-region--top-container {
          margin-bottom: 100px;
        }
      }
    }

    &.panels-layout--sectioned-top-light-dark-light-dark {

      &.panels-display--variant-1 {

        .panels-pane-region--light-section-1 {
          margin-bottom: 60px;
        }
      }
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  .page-taxonomy-term {

    &.panels-layout--content-with-left-and-right-sidebar-top-footer {

      &.panels-display--variant-2 {

        .panels-pane-region--sidebar-right {
          margin-top: -100px;
        }
      }

      &.panels-display--variant-6 {

        .panels-pane-region--sidebar-right {
          margin-top: $md-breadcrumb-padding-vertical;
        }
      }
    }

    &.panels-layout--sectioned-top-light-dark-light-dark {

      &.panels-display--variant-1 {

        .breadcrumb {
          margin-top: 0 - $md-sectioned-inner-spacing;
        }
      }
    }
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  .page-taxonomy-term {

    &.panels-layout--content-with-left-and-right-sidebar-top-footer {

      &.panels-display--variant-2 {

        .panels-pane-region--sidebar-right {
          margin-top: -150px;
        }
      }

      &.panels-display--variant-6 {

        .panels-pane-region--sidebar-right {
          margin-top: $lg-breadcrumb-padding-vertical;
        }
      }
    }

    &.panels-layout--sectioned-top-light-dark-light-dark {

      &.panels-display--variant-1 {

        .breadcrumb {
          margin-top: 0 - $lg-sectioned-inner-spacing;
        }
      }
    }
  }
}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
