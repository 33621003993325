// |--------------------------------------------------------------------------
// | Home
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .node-type-os2web-kulturnaut-knactivity
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin kultunaut-activity-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.page-front {

  &.panels-layout--content-with-left-and-right-sidebar-top-footer {

    .panels-pane-region--content-first {
      margin-bottom: 0;
    }

    .panels-pane-region--content-secondary {
      @extend .boxy__body;
    }

    .panels-pane-region--sidebar-right {
      @extend .boxy__body;

      padding-top: $boxy-spacing-left-right;

      border: 0;

      font-size: $font-size-large;

      box-shadow: $boxy-shadow-body-box-shadow;
      background-color: $boxy-shadow-body-background-color;

      .pane-title {
        margin: 0;
        margin-bottom: 4px;

        font-family: $font-family-base;
        font-size: $font-size-small;
        font-weight: $font-weight;

        text-transform: uppercase;

        color: $text-color-light;
      }
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
