// |--------------------------------------------------------------------------
// | List advanced
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .entity-list-advanced
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin entity-list-advanced-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.entity-list-advanced {
  display: flex;
}

.entity-list-advanced__image {

  img {
    width: 100%;
  }
}

.entity-list-advanced__heading {

  + .entity-list-advanced__search-snippet,
  + .entity-list-advanced__path {
    margin-top: 10px;
  }
}
.entity-list-advanced__heading__title {
  @extend .heading-h3;

  margin: 0;
  padding: 0;
}

.entity-list-advanced__body {
  font-size: $font-size-large;
}

.entity-list-advanced__search-snippet {}

.entity-list-advanced__path {
  font-size: $font-size-small;
  color: $text-color-light;
}


// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .entity-list-advanced {
    flex-direction: column;
  }

  .entity-list-advanced__image {

    + .entity-list-advanced__body {
      margin-top: floor( ( $grid-gutter-width / 2 ) );
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .entity-list-advanced {
    align-items: center;
  }

  .entity-list-advanced__image {
    flex-shrink: 0;
    flex-basis: 220px;

    + .entity-list-advanced__body {
      margin-left: floor( ( $grid-gutter-width / 2 ) );
    }
  }

  .entity-list-advanced__body {
    flex-grow: 1;
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
