$boxy-spacing-left-right: $grid-gutter-width !default;

// Heading
$boxy-heading-spacing-top-bottom: 5px !default;
$boxy-heading-border: 0 !default;
$boxy-heading-background-color: transparent !default;

$boxy-heading-title-color: $headings-color !default;

// Body
$boxy-body-spacing-top-bottom: 10px !default;
$boxy-body-border: 2px solid #f4f6f5;
$boxy-body-background-color: transparent;

// Footer
$boxy-footer-spacing-top-bottom: 10px !default;
$boxy-footer-border: 0 !default;
$boxy-footer-background-color: transparent !default;
