// |--------------------------------------------------------------------------
// | Content with left and right sidebar with top and footer
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .panels-layout--content-with-left-and-right-sidebar-top-footer
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

$panel-separator-height: 25px;

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin panels-layout-content-with-left-and-right-sidebar-top-footer-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.panels-layout--content-with-left-and-right-sidebar-top-footer {

  .pane-views-panes {

    .pane-title {
      @extend .heading-h3;

      margin: 0 0 $panel-separator-height 0;
    }
  }

  .panels-pane-region {
    margin-bottom: $grid-gutter-width;

    // Banner
    &.panels-pane-region--top {
      margin-bottom: 0;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
