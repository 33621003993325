// Static
$flexy-header-static-background-color: $body-bg !default;

// Sticky
$flexy-header-sticky-zindex: $zindex-navbar !default;
$flexy-header-sticky-background-color: rgba($body-bg, .95) !default;
$flexy-header-sticky-unpinned-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .15) !default;

// Responsive (static)
$xs-flexy-header-static-row-height: 60px !default;
$xs-flexy-header-static-logo-height: 38px;

$sm-flexy-header-static-row-height: 120px !default;
$sm-flexy-header-static-logo-height: 50px;

$md-flexy-header-static-row-height: 180px !default;
$md-flexy-header-static-logo-height: 60px;

$lg-flexy-header-static-row-height: 190px !default;
$lg-flexy-header-static-logo-height: 60px;

// Responsive (sticky)
$xs-flexy-header-sticky-row-height: 60px !default;
$xs-flexy-header-sticky-logo-height: 40px !default;

$sm-flexy-header-sticky-row-height: 60px !default;
$sm-flexy-header-sticky-logo-height: 40px !default;

$md-flexy-header-sticky-row-height: 60px !default;
$md-flexy-header-sticky-logo-height: 40px !default;

$lg-flexy-header-sticky-row-height: 60px !default;
$lg-flexy-header-sticky-logo-height: 40px !default;
