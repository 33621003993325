// |--------------------------------------------------------------------------
// | Teaser
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .entity-teaser--velkomst
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin entity-teaser-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.entity-teaser--velkomst {

  .entity-teaser__heading {

    + .entity-teaser__body {
      margin-top: $grid-gutter-width;
    }
  }

  .entity-teaser__heading__title {
    font-weight: $headings-font-weight-light;
  }

  .entity-teaser__body {
    padding: 0;
  }

  .field-name-body {
    font-size: $font-size-large;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
