// |--------------------------------------------------------------------------
// | Map link
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .field-name-field-os2web-taxonomies-maplink
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin field-name-field-os2web-taxonomies-maplink-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.field-name-field-os2web-taxonomies-maplink {

  a {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-block;
    @extend .btn-lg;

    &:before {
      @extend .icon;

      content: $fa-var-map-marker;

      margin-right: 6px;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
