// |--------------------------------------------------------------------------
// | Term description
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .pane-term-description
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin pane-term-description-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.pane-term-description {
  font-size: 22px;
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
